<template>
    <div class="pa-5 ma-3 white rounded-lg">
        <div class="subheading grey--text mb-9">Order List</div>

        <v-row>
            <v-col cols="6" offset="6" class="pt-0">
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Filter" single-line hide-details></v-text-field>
            </v-col>
        </v-row>
        
        <v-data-table :headers="headers" :items="items" :footer-props="paginationOptions" :search="search"
            item-key="id" :options="pagination" :loading="loading" @click:row="toOrderDetails" @pagination="onPagination">
            <template v-slot:item.wantDate="{ item }">
                {{ formatDate( item.wantDate ) }}
            </template>
            <template v-slot:item.status="{ item }">
                <span :class="orderStatusColor(item.status) + '--text'">{{ item.status }}</span>
            </template>
        </v-data-table>

    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';

export default {
    data: () => ({
        headers     : [
            { text: 'Order #', value: 'foreignId', sortable: true },
            { text: 'Customer', value: 'customer.fullName', sortable: true },
            { text: 'Use Date', value: 'useDate', sortable: true },
            { text: 'Pickup Date', value: 'wantDate', sortable: true },
            { text: 'Return Date', value: 'returnDate', sortable: true },
            { text: 'Status', value: 'status', sortable: true },
        ],
        pagination: {
            sortBy: ['orderDate']
        },
        paginationOptions: { itemsPerPageOptions: [ 25, 50, -1 ] },
        loading: false,
        search: '',
    }),
    computed: {
        ...mapGetters({
            items       : 'orders/orders',
            hasMore     : 'orders/hasMore',
            needRefresh : 'orders/needRefresh'
        }),
    },
    mounted() {
        this.init();
    },
    methods: {
        ...mapActions({
            loadOrders: 'orders/loadOrders'
        }),
        async init() {
            try {
                if( !this.needRefresh )
                    return; 

                this.loading = true;
                await this.loadOrders();
                this.loading = false;
            }
            catch(error) {
                this.loading = false;
            }
        },
        formatDate(d) {
            return moment(d).format('YYYY-MM-DD @ hh:mm a');
        },
        toOrderDetails(item) {
            this.$router.push(`/orders/${item.id}`);
        },
        onPagination(v) {
            if( v.pageCount != v.page || this.search != '' ) {
                return;
            }

            this.loadMore();
        },
        async loadMore() {
            try {
                if( !this.hasMore )
                    return;
                
                this.loading = true;
                await this.loadOrders();
                this.loading = false;
            }
            catch(error) {
                this.loading = false;
            }
        }
    }
}
</script>

<style lang="scss" scoped>

</style>